import React from "react"

import Layout from "../components/Layout"
import Image from "../components/image"
import SEO from "../components/seo"

import "../styles/global.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p>
      After 11(!!) years in business, the Dapper Dog co. is now closed. Over the
      last several years we've had some pretty big life changes - welcoming new
      family members, big overseas moves, saying goodbye to our beloved Barkley
      - and though we've kept DDco running, we haven't been able to give it the
      same love we did in the earlier years.
    </p>

    <p>
      So, looking ahead to the new year (and new decade!), we've decided to make
      a graceful exit now and end on a very happy, albeit bittersweet, note.
    </p>

    <p>
      Together, my sister Tanya and I started the Dapper Dog co. because we love
      our pups so much and created the only dog collars on the market made in
      the same manner and quality as men's neckties. We soon began making bow
      tie collars as well. They were such a hit at the local fairs and markets
      that we started theDapperDogco.com which I began running full time from my
      Brooklyn studio.
    </p>

    <p>
      These were some of the happiest times and we thank all of our customers,
      stockists, family and friends for supporting us through the years. Working
      with and for dog lovers is truly special and we will be forever grateful.
    </p>

    <p>
      Thank you. Thank you. Thank you! And we wish you the very best in 2020 and
      beyond!✨
    </p>
    <p>Megan &amp; Tanya</p>
    <Image />
  </Layout>
)

export default IndexPage
